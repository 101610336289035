.legal-container {
  margin-top: $spacer-x6;

  .document-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include media-down-breakpoint(sm) {
      width: 100%;
    }

    .left-col {
      margin-right: $spacer-x6;
      @include media-down-breakpoint(md) {
        margin-right: 0;
      }
    }

    .legal-menu {
      padding: $spacer-x4;
      min-width: 384px;
      box-shadow: $dropshadow-8;
      display: block;

      &.xl {
        @include media-down-breakpoint(md) {
          display: none;
        }
      }

      &.xs {
        display: none;
        @include media-down-breakpoint(md) {
          margin-bottom: $spacer-x4;
          display: block;
          min-width: 0;
        }
      }

      .item-text {
        display: flex;
        padding: $spacer-x2;

        text-decoration: none;

        color: $blue-700;

        &.active {
          background-color: $gray-100;
        }
      }
    }

    .document-name {
      @include media-down-breakpoint(sm) {
        width: 100%;
      }

      display: flex;
      flex-wrap: wrap;
    }

    .legal-document {
      margin-bottom: $spacer-x8;

      .document-text {
        margin-bottom: $spacer-x6;
        line-height: 24px;
        font-weight: 400;

        @include media-down-breakpoint(sm) {
          font-size: 14px;
          width: 100%;
        }
      }

      .version {
        color: $blue-700;
        margin-bottom: $spacer-x4;

        display: flex;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .document-link {
    color: $blue-700;
    margin-bottom: $spacer-x4;

    display: flex;

    &:hover {
      text-decoration: underline;
    }
  }
}

.d-flex-1 {
  flex: 1;
}
