.background-calc-fee {
  background-image: url($urlPrefix + '/img/calcFee/backgroundCalcFeeXL.svg');
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  height: 162px;
  padding: 48px 0;

  @include media-down-breakpoint(xl) {
    background-image: url($urlPrefix + '/img/calcFee/backgroundCalcFeeLg.svg');
  }

  @include media-down-breakpoint(lg) {
    background-image: url($urlPrefix + '/img/calcFee/backgroundCalcFeeMd.svg');
  }

  @include media-down-breakpoint(md) {
    background-image: url($urlPrefix + '/img/calcFee/backgroundCalcFeeSm.svg');
    height: 124px;
    padding: $spacer-x6 0;
  }

  @include media-down-breakpoint(sm) {
    background-image: url($urlPrefix + '/img/calcFee/backgroundCalcFeeXs.svg');
  }

  display: flex;
  flex-direction: row;
  align-items: center;

  .calc-fee-h1 {
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    color: $white;
    text-align: center;

    @include media-down-breakpoint(md) {
      text-align: start;
      font-size: 32px;
      line-height: 39px;
    }
  }
}

.calc-fee-content {
  flex-grow: 1;
  height: 100%;

  padding-top: $spacer-x10;
  padding-bottom: $spacer-x10;
  @include media-down-breakpoint(sm) {
    padding-top: $spacer-x4;
    padding-bottom: $spacer-x4;
  }
}
