.background-tariff {
  background-image: url($urlPrefix + '/img/tariffPage/backgroundTariffXL.svg');
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  height: 468px;
  padding: 4rem 0;

  @include media-down-breakpoint(xl) {
    background-image: url($urlPrefix + '/img/tariffPage/backgroundTariffLg.svg');
  }

  @include media-down-breakpoint(lg) {
    background-image: url($urlPrefix + '/img/tariffPage/backgroundTariffMd.svg');
  }

  @include media-down-breakpoint(md) {
    background-image: url($urlPrefix + '/img/tariffPage/backgroundTariffSm.svg');
  }

  @include media-down-breakpoint(sm) {
    background-image: url($urlPrefix + '/img/tariffPage/backgroundTariffXs.svg');
    height: 319px;
    padding: 3rem 0;
  }

  .subscription-price-caption {
    font-size: 64px;
    line-height: 78px;
    font-weight: bold;
    color: $white;

    @include media-down-breakpoint(sm) {
      font-size: 32px;
      line-height: 39px;
    }
  }

  .description-tariff {
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: $white;
    margin-bottom: 3.5rem;

    @include media-down-breakpoint(sm) {
      margin-bottom: $spacer-x8;
    }
  }
}

.tariff-title {
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: $primary;
  text-align: center;
  margin-bottom: $spacer-x8;
}

.subscription-periods {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-down-breakpoint(sm) {
    flex-direction: column;
  }

  .caption-show-price {
    color: $gray-700;
    font-weight: normal;
    margin-bottom: 0;
    margin-right: $spacer-x4;

    @include media-down-breakpoint(sm) {
      margin-right: 0;
      margin-bottom: $spacer-x4;
    }
  }
}

.tariff-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacer-x6;
  box-shadow: $dropshadow-32;
  background-color: $white;
  border-radius: $spacer-x2;
  transition: all 150ms;
  height: 100%;

  &:hover {
    box-shadow: $dropshadow-1;
  }

  .tariff-name {
    font-size: 24px;
    line-height: 24px;
    color: $primary;
    text-align: center;
  }

  .cross-out-price {
    text-decoration: line-through;
    color: $gray-500;
  }

  .tariff-price {
    font-weight: bold;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    margin-bottom: $spacer-x1;
    margin-top: 20px;
  }

  .sale-price {
    margin-top: 0;
    padding: 0 $spacer-x4;
    border-radius: $spacer-x1;
    background-color: $light-yellow;
  }
}

.spacer-blocks {
  margin-bottom: 4.5rem;
}

.spacer-questions-block {
  margin-bottom: 3rem;
}

.table-plan-options {
  box-shadow: $dropshadow-32;
  background-color: $white;
  border-radius: $spacer-x2;

  .thead-top {
    box-shadow: $dropshadow-4;
    position: sticky;
    top: 0;
    z-index: 10;

    background-color: $gray-100;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-collapse: separate !important;
  }

  .thead-bottom {
    margin-top: 72px;
    box-shadow: 0px -16px 24px 0px rgba(10, 48, 104, 0.06), 0px -4px 10px 0px rgba(10, 54, 106, 0.10);
    background-color: $gray-100;
    position: sticky;
    bottom: 0;
    z-index: 10;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  thead {
    tr {
      border-radius: $spacer-x2;

      th {
        padding: $spacer-x6;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $primary;
        border-right: 1px solid rgba($light-sapphire, 0.26);

        &:last-child {
          border: none;
        }

        @include media-down-breakpoint(md) {
          padding: $spacer-x4;
        }
      }
    }
  }

  .tariff-thead-pc {
    @include media-down-breakpoint(md) {
      display: none;
    }
  }

  .tariff-thead-pc-item {
    padding: $spacer-x4 $spacer-x6;
    width: 18%
  }

  .tariff-thead-m {
    display: none;
    @include media-down-breakpoint(md) {
      display: table-row;
    }

    .tariff-thead-m-item {
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
      padding: $spacer-x4 $spacer-x2 $spacer-x4 $spacer-x2;
      background-color: $gray-100;
      color: $primary;

      width: 25%;

      &.active {
        color: $white;
        background-color: $primary;
      }
    }
  }

  .tariff-thead-b-pc {
    @include media-down-breakpoint(md) {
      display: none;
    }
  }

  .td-feature-input {
    vertical-align: top;
    padding: $spacer-x2 $spacer-x6;

    @include media-down-breakpoint(md) {
      vertical-align: top;
      padding: $spacer-x2;
    }

    &.td-feature-input-pc {
      @include media-down-breakpoint(md) {
        display: none;
      }
    }
  }

  .td-feature-value {
    vertical-align: top;
    padding: $spacer-x3 $spacer-x6;

    @include media-down-breakpoint(md) {
      padding: $spacer-x3 $spacer-x4;
      text-align: end;
      vertical-align: top;
    }
  }

  .tariff-thead-b-m {
    display: none;
    @include media-down-breakpoint(md) {
      display: table-row;
    }

    .tariff-thead-m-item {
      background-color: $gray-100;
      color: $primary;

      &.active {
        color: $white;
        background-color: $primary;
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid transparent;
      border-collapse: collapse;

      &:last-child {
        border-bottom: none;
      }

      &:first-child {
        border-top: none;
      }

      td {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: $gray-900;

        padding: $spacer-x4 $spacer-x6;
        border-right: 1px solid rgba($light-sapphire, 0.26);

        &:last-child {
          border: none;
        }
      }
    }
  }

  .cell-hide {
    @include media-down-breakpoint(md) {
      display: none;
    }
  }

  .tariff-body-tr-pc {
    @include media-down-breakpoint(md) {
      display: none;
    }
  }

  .tariff-body-tr-m {
    display: none;
    @include media-down-breakpoint(md) {
      display: table-row;
    }
  }

  .group-name {
    padding: $spacer-x6 $spacer-x4 $spacer-x3 $spacer-x4;
  }

  .string-hide {
    display: none;

    @include media-down-breakpoint(md) {
      display: contents;
      border-bottom: 1px solid rgba($light-sapphire, 0.26);
    }

    .unite-cell {
      border-bottom: 1px solid rgba($light-sapphire, 0.26);
      border-top: 1px solid rgba($light-sapphire, 0.26);
    }
  }
}


.payment-info {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invoice-container {
  max-width: 490px;
}

.tariff-block-faq {
  padding-bottom: 4.5rem;
  padding-top: 4.5rem;

  @include media-down-breakpoint(sm) {
    padding-bottom: 3em;
    padding-top: 3rem;
  }
}

.tariff-select {
  width: 100%;

  .tariff-select-interval {
    color: $gray-900;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: $spacer-x2;
  }

  .tariff-select-price-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $spacer-x2;

    .tariff-select-price {
      color: $black;
      font-size: 32px;
      line-height: 42px;
      font-weight: 700;
      margin-bottom: 0;
    }

    .tariff-select-sale {
      color: $gray-500;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      text-decoration: line-through;
      margin-left: $spacer-x1;
      margin-bottom: 0;
    }
  }
}

.text-fs-14-16 {
  font-size: 14px;
  line-height: 16px;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.vertical-align-bottom {
  vertical-align: bottom;
}
