.main-content {
  margin-top: 48px;

  @include media-down-breakpoint(xl) {
    margin-top: 64px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .main-image {
    width: 588px;
    height: 583px;

    @include media-down-breakpoint(xl) {
      width: 485px;
      height: 482px;
    }

    @include media-down-breakpoint(lg) {
      width: 370px;
      height: 367px;
    }

    @include media-down-breakpoint(md) {
      width: 370px;
      height: 367px;
    }

    @include media-down-breakpoint(sm) {
      width: 290px;
      height: 287px;
    }
  }

  .main-text {
    margin-left: 72px;

    @include media-down-breakpoint(xl) {
      margin-left: 48px;
    }

    @include media-down-breakpoint(md) {
      text-align: center;
      margin-left: 0;
      margin-top: $spacer-x4;
    }

    .header-text {
      font-size: 72px;
      line-height: 88px;
      font-weight: 700;

      font-family: Montserrat, sans-serif;
      color: $primary;

      margin-bottom: $spacer-x6;

      @include media-down-breakpoint(sm) {
        font-size: 32px;
        line-height: 36px;
        font-weight: 700;

        font-family: Montserrat, sans-serif;
        color: $primary;

        margin-bottom: $spacer-x4;
      }
    }

    .description-text {
      font-size: 44px;
      font-weight: 400;
      line-height: 60px;

      font-family: Open Sans, sans-serif;
      color: $primary;

      margin-bottom: $spacer-x6;
      width: 510px;

      @include media-down-breakpoint(sm) {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;

        font-family: Open Sans, sans-serif;
        color: $primary;

        margin-bottom: $spacer-x4;
        max-width: 320px;
      }
    }
  }
}

.landing-content {
  background-color: $gray-100;

  .section-title {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: $primary;
  }

  .block-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: $primary;
  }

  .block-description {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $gray-800;
  }

  .mb-x18 {
    margin-bottom: 72px;
  }

  .main-info {
    height: 100%;
    padding-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @include media-down-breakpoint(sm) {
      flex-direction: column;
      justify-content: center;
      padding-bottom: 48px;
    }

    .main-info-image {
      display: flex;
      flex-direction: column;
      width: 100%;

      > svg {
        width: 100%;
        height: auto;
      }

      @include media-down-breakpoint(sm) {
        align-items: center;
        margin-bottom: $spacer-x4;
        > svg {
          width: 128px;
          height: 128px;
        }
      }
    }

    .main-info-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 87px;
      height: 100%;

      @include media-down-breakpoint(md) {
        align-items: center;
        padding-left: 0;
      }

      .main-info-button {
        margin-right: $spacer-x6;

        @include media-down-breakpoint(sm) {
          width: 100%;
          margin-right: 0;
          margin-bottom: $spacer-x6;
        }
      }

      .main-info-content-title {
        font-weight: 700;
        font-size: 64px;
        line-height: 78px;
        color: $primary;

        margin-bottom: $spacer-x6;

        @include media-down-breakpoint(md) {
          font-size: 36px;
          line-height: 40px;
          margin-bottom: $spacer-x4;
          text-align: center;
        }
      }

      .main-info-content-description {
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: $primary;

        margin-bottom: 48px;

        @include media-down-breakpoint(md) {
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: $spacer-x4;
          text-align: center;
        }
      }

      .main-info-content-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include media-down-breakpoint(sm) {
          flex-direction: column;
        }
      }
    }
  }

  .can-tell-more {
    padding-top: 102px;
    padding-bottom: 102px;

    .can-tell-more-h2 {
      text-align: center;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      color: $primary;
      margin-bottom: 72px;

      @include media-down-breakpoint(sm) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 64px;
      }
    }

    .can-tell-more-margin {
      margin-bottom: 72px;

      @include media-down-breakpoint(sm) {
        margin-bottom: 56px;
      }
    }

    .can-tell-more-block {
      display: flex;
      flex-direction: column;
      padding-top: $spacer-x4;

      @include media-down-breakpoint(sm) {
        padding-top: 0;
      }

      &.left {
        padding-left: 126px;

        @include media-down-breakpoint(sm) {
          padding-left: 0;
        }
      }

      &.right {
        padding-right: 126px;

        @include media-down-breakpoint(sm) {
          padding-right: 0;
        }
      }

      .can-tell-more-block-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: $primary;
        margin-bottom: $spacer-x6;

        @include media-down-breakpoint(sm) {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          margin-bottom: $spacer-x4;
        }
      }

      .can-tell-more-block-description {
        font-size: 20px;
        line-height: 24px;
        color: $gray-800;
        margin-bottom: $spacer-x8;

        @include media-down-breakpoint(sm) {
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          margin-bottom: $spacer-x4;
        }
      }

      .can-tell-more-block-items {
        display: flex;
        flex-direction: row;

        @include media-down-breakpoint(sm) {
          justify-content: center;
          flex-wrap: wrap;
        }

        .can-tell-more-block-item {
          display: flex;
          flex-direction: column;
          align-items: center;

          &.margin {
            margin-bottom: $spacer-x6;
            @include media-down-breakpoint(sm) {
              margin-bottom: $spacer-x4;
            }
          }

          @include media-down-breakpoint(sm) {
            width: 126px;
            margin-right: $spacer-x2;
            margin-left: $spacer-x2;
          }


          .can-tell-more-block-item-img {
            display: flex;
            margin-bottom: $spacer-x4;

            @include media-down-breakpoint(sm) {
              align-items: center;
              > svg {
                width: 86px;
                height: 86px;
              }
            }
          }

          .can-tell-more-block-item-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: $gray-800;
            margin-bottom: 0;
          }
        }
      }
    }

    .can-tell-more-img {
      display: block;
      @include media-down-breakpoint(md) {
        display: none;
      }
    }

    @include media-down-breakpoint(sm) {
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }

  .we-also-have {
    padding-top: 102px;

    background-color: $white;
    padding-bottom: 78px;

    @include media-down-breakpoint(sm) {
      padding-top: 48px;
      padding-bottom: 32px;
    }

    .we-also-have-h2 {
      text-align: center;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      color: $primary;
      margin-bottom: 48px;

      @include media-down-breakpoint(sm) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 40px;
      }
    }

    .we-also-have-card-margin {
      margin-bottom: $spacer-x6;
      @include media-down-breakpoint(sm) {
        margin-bottom: $spacer-x4;
      }
    }

    .we-also-have-card {
      padding: $spacer-x6;
      background-color: $gray-100;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;

      .we-also-have-card-img {
        display: flex;
        margin-bottom: $spacer-x4;

        > svg {
          width: 200px;
          height: 200px;
        }
      }

      .we-also-have-card-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: $primary;
        margin-bottom: $spacer-x4;
      }

      .we-also-have-card-description {
        font-size: 20px;
        line-height: 24px;
        color: $gray-800;
        margin-bottom: 0;
        @include media-down-breakpoint(sm) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  .suitable-for-whom {
    padding-top: 102px;

    padding-bottom: 78px;

    .suitable-for-whom-h2 {
      text-align: center;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      color: $primary;
      margin-bottom: 48px;

      @include media-down-breakpoint(sm) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 24px;
      }
    }

    .suitable-for-whom-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .suitable-for-whom-card {
        padding: $spacer-x4;
        background: #D6EBE7;
        border-radius: 48px;
        margin-left: $spacer-x3;
        margin-right: $spacer-x3;
        margin-bottom: $spacer-x6;

        @include media-down-breakpoint(sm) {
          padding: $spacer-x2;
          margin-left: $spacer-x2;
          margin-right: $spacer-x2;
          margin-bottom: $spacer-x4;
          border-radius: 32px;
        }

        .suitable-for-whom-card-text {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #1A6661;
          text-align: center;
          margin-bottom: 0;

          @include media-down-breakpoint(sm) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    @include media-down-breakpoint(sm) {
      padding-top: 48px;
      padding-bottom: 32px;
    }
  }

  .reviews {
    padding-top: 102px;
    background-color: $white;
    padding-bottom: 78px;

    @include media-down-breakpoint(sm) {
      padding-top: 48px;
      padding-bottom: 32px;
    }

    .reviews-h2 {
      text-align: center;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      color: $primary;
      margin-bottom: 48px;

      @include media-down-breakpoint(sm) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 40px;
      }
    }

    .reviews-card-margin {
      margin-bottom: $spacer-x6;
      @include media-down-breakpoint(sm) {
        margin-bottom: $spacer-x4;
      }
    }

    .reviews-card {
      padding: $spacer-x6;
      border-radius: 16px;
      box-shadow: $dropshadow-32;
      background-color: $white;

      display: flex;
      flex-direction: column;
      height: 100%;

      @include media-down-breakpoint(sm) {
        height: auto;
      }

      .reviews-card-message {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $gray-900;
        margin-bottom: $spacer-x4;

        @include media-down-breakpoint(sm) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .reviews-card-user {
        display: flex;
        flex-direction: row;

        .reviews-card-user-info {
          padding-left: $spacer-x4;
          display: flex;
          flex-direction: column;

          .reviews-card-user-name {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $primary;
            margin-bottom: $spacer-x1;
          }

          .reviews-card-user-role {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $gray-700;
          }
        }
      }
    }
  }

  .contact-form {

    padding-top: 102px;
    padding-bottom: 102px;

    @include media-down-breakpoint(sm) {
      padding-top: 48px;
      padding-bottom: 48px;
    }

    .contact-form-h2 {
      text-align: center;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      color: $primary;
      margin-bottom: $spacer-x4;

      @include media-down-breakpoint(sm) {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 16px;
      }
    }

    .contact-form-description {
      color: $gray-700;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 48px;

      @include media-down-breakpoint(sm) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: $spacer-x6;
      }
    }

    .contact-form-content {
      padding: $spacer-x6;
      background-color: $white;
      box-shadow: $dropshadow-56;
      align-self: center;
      border-radius: 16px;
      width: 100%;
      max-width: 588px;
      margin-bottom: 48px;

      display: flex;
      flex-direction: column;

      @include media-down-breakpoint(sm) {
        margin-bottom: $spacer-x8;
      }

      transition: all 300ms;

      .sent-success-text {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: $gray-700;
        margin-bottom: 0;
      }
    }
  }
}

