.subject-card {
  box-shadow: $dropshadow-8;
  margin-bottom: $spacer-x6;
  padding: $spacer-x4;
  width: 490px;
  border: $border;
  transition: all 150ms;

  @include media-down-breakpoint(sm) {
    width: 100%;
  }

  .subject-card-image {
    margin-right: $spacer-x6;

    .image-content {
      width: 176px;
      height: 176px;
    }

    @include media-down-breakpoint(sm) {
      display: none;
    }
  }

  &:hover {
    box-shadow: none;
    border: $border;
  }
}
