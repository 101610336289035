body {
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: $spacer-x4;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-300;
    border-radius: $spacer-x2;

    &:hover {
      background-color: $gray-500;
    }
  }
}


.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.header-site {
  box-shadow: $dropshadow-1;
  background-color: $white;
  position: sticky;
  top: 0;
  z-index: 1000;

  .header-site-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: $spacer-x2;
    padding-bottom: $spacer-x2;

    @include media-down-breakpoint(sm) {
      padding-top: $spacer-x1;
      padding-bottom: $spacer-x1;
    }
  }

  .header-site-button-price {
    @include media-down-breakpoint(sm) {
      display: none;
    }
  }

  .header-site-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include media-down-breakpoint(sm) {
      display: none;
    }
  }

  .header-site-menu {
    display: none;
    @include media-down-breakpoint(sm) {
      display: block;
    }

    .header-site-menu-block {

      .hdm-content {
        z-index: 1000;
        position: absolute;
        right: 16px;
        background-color: $white;
        box-shadow: $dropshadow-16;
        border-radius: 4px;
        width: 248px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding: $spacer-x4;
      }
    }
  }
}

.footer-site {
  background-color: $gray-900;

  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: $spacer-x8;

    .footer-content-info {
      display: flex;
      flex-direction: column;
      margin-bottom: $spacer-x8;

      .footer-content-info-logo {
        margin-bottom: $spacer-x6;
      }

      .footer-content-info-telegram {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .footer-content-info-telegram-text {
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: $gray-200;
          margin-right: $spacer-x4;
        }

        .footer-content-info-telegram-icon {
          > svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    .footer-content-links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 0;

      .footer-content-link {
        font-size: 13px;
        line-height: 16px;
        color: $gray-200;
        text-decoration: none;

        margin-right: $spacer-x1;
        margin-left: $spacer-x1;
        margin-bottom: $spacer-x2;
      }

      @include media-down-breakpoint(sm) {
        flex-direction: column;

        :last-child {
          margin-bottom: 0;
        }

        .footer-content-link {
          &.l-divider {
            display: none;
          }
        }
      }
    }

    .footer-content-divider {
      width: 100%;
      margin-top: $spacer-x6;
      margin-bottom: $spacer-x6;
      @include media-down-breakpoint(sm) {
        margin-top: $spacer-x8;
        margin-bottom: $spacer-x8;
      }
    }

    .footer-content-date {
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: $gray-600;
      margin-bottom: $spacer-x6;
    }
  }
}

